import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/forpad/forpad/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "กำหนดการสำคัญสำหรับแพทย์"
    }}>{`กำหนดการสำคัญสำหรับแพทย์`}</h3>
    <p>{`๑๒ พฤศจิกายน ๒๕๖๗ เลือกทีมเพื่อแพทย์ 34-53, 175-188 ทั้งทีม 34 คน (เลือกได้ถึง ๑๔ มกราคม ๒๕๖๘)`}</p>
    <h3 {...{
      "id": "นโยบายทีมเพื่อแพทย์"
    }}>{`นโยบายทีมเพื่อแพทย์`}</h3>
    <ol>
      <li parentName="ol">{`ดูแลเพื่อนแพทย์ให้เป็นไปตามพระราชบัญญัติวิชาชีพเวชกรรม ปีพ.ศ.2525`}</li>
      <li parentName="ol">{`ส่งเสริมให้เพื่อนแพทย์ดำรงตนให้เหมาะสมตามสถานะความเป็นอยู่ที่มีคุณภาพและอยู่ในมาตรฐานวิชาชีพ`}</li>
      <li parentName="ol">{`ส่งเสริมพัฒนาวิชาการและเทคโนโลยีที่ก้าวหน้า และอิสระทั้งภาครัฐและเอกชน`}</li>
    </ol>
    <h3 {...{
      "id": "แผนปฏิบัติภายใน-๑๐๐-วันหากได้รับเลือกยกทีม-๓๔-คน"
    }}>{`แผนปฏิบัติภายใน ๑๐๐ วันหากได้รับเลือกยกทีม ๓๔ คน`}</h3>
    <ul>
      <li parentName="ul">{`จัดประชุมใหญ่สมาชิกแพทยสภา ภายใน ๔๕ วัน (ไม่เคยจัดมา ๕๐ กว่าปีแล้ว) `}</li>
      <li parentName="ul">{`จัดตั้งคณะอนุกรรมการ/คณะทำงาน นำข้อเสนอทางออกปัญหาแพทย์แต่ละประเด็น จัดทำรายงาน`}</li>
      <li parentName="ul">{`เป็นเจ้าภาพ ยื่น ผลักดัน และติดตาม กับรัฐบาล/กระทรวงสาธารณสุขในการแก้ไขปัญหาแพทย์และที่เกี่ยวข้อง `}</li>
      <li parentName="ul">{`สนับสนุนรัฐในการออกกฎหมายบริหารบุคลากรการแพทย์ แยกตัวจาก กพ. `}</li>
      <li parentName="ul">{`ผลักดันกฎหมายเกี่ยวกับการทำงานของแพทย์ เช่นกฎกระทรวงว่าด้วยชั่วโมงการทำงานของแพทย์`}</li>
      <li parentName="ul">{`ผลักดันแก้ไขกฎหมายบริหารงบประมาณรักษาผู้ป่วย-ส่งเสริมสุขภาพ-ป้องกันโรคให้เกิดดุลย์`}</li>
    </ul>
    <h3 {...{
      "id": "ประเด็นที่จะทำ"
    }}>{`ประเด็นที่จะทำ`}</h3>
    <ul>
      <li parentName="ul">{`แพทย์จบใหม่ แพทย์ใช้ทุน จะได้เทรย อย่างเป็นธรรม `}</li>
      <li parentName="ul">{`รู้จริง แก้จริง ช่วยจริง เรื่องกฎหมายเพื่อแพทย์`}</li>
      <li parentName="ul">{`แก้ปัญหาแพทย์ส่วนหนึ่งที่ทำงานเกินมนุษย์`}</li>
      <li parentName="ul">{`แก้ไขปัญหาความไม่เป็นธรรมที่แพทย์ได้รับในระบบงานสาธารณสุข`}</li>
      <li parentName="ul">{`แก้ไขปัญหาระบบงบประมาณสาธารณสุขและการใช้จ่ายเงิน`}</li>
      <li parentName="ul">{`แพทย์ไทยสามัคคี เพื่อมวลแพทย์และคนไทย`}</li>
      <li parentName="ul">{`รวมพลคนเสื้อกาวน์ เปลี่ยนแพทยสภาให้ทำเพื่อแพทย์`}</li>
      <li parentName="ul">{`แพทย์ถูกลงโทษจริยธรรมที่ไม่เป็นตามหลักนิติธรรม ต้องแก้ไข `}</li>
    </ul>
    <h3 {...{
      "id": "แนวทางการทำงานหากแพทย์วางใจเลือกยกทีมไปทำงานแทนแพทย์"
    }}>{`แนวทางการทำงานหากแพทย์วางใจเลือกยกทีมไปทำงานแทนแพทย์`}</h3>
    <ul>
      <li parentName="ul">{`รับฟัง ทำจริง เพื่อแพทย์`}</li>
      <li parentName="ul">{`จัดให้แพทย์มีส่วนร่วมในการแก้ไขปัญหาการแพทย์ทั้งทางวิชาการและการประกอบวิชาชีพ`}</li>
      <li parentName="ul">{`จัดให้มีคณะอนุกรรมการ คณะทำงานเฉพาะเรื่อง โดยมีแพทย์หลากหลายภาคส่วน`}</li>
      <li parentName="ul">{`ทำงานกับรัฐ-เอกชน-และบุคลากรทางการแพทย์ทุกกลุ่ม โดยยึดประโยชน์สมาชิกและประชาชนเป็นศูนย์กลาง`}</li>
    </ul>
    <h3 {...{
      "id": "ผลงานทีมเพื่อแพทย์และทีมนำของทีมเพื่อแพทย์"
    }}>{`ผลงานทีมเพื่อแพทย์และทีมนำของทีมเพื่อแพทย์`}</h3>
    <ul>
      <li parentName="ul">{`ทีมนำเป็นผู้จัดทำร่างและเสนอร่างกฎหมาย แยกบุคลากรการแพทย์กระทรวงสาธารณสุข ออกจาก กพ. เมื่อสิงหาคม ๒๕๕๕ เสนอเข้าสู่รัฐสภา ในชื่อ ร่าง พรบ.ระเบียบข้าราชการและบุคลากรสาธารณสุข พ.ศ…`}</li>
      <li parentName="ul">{`จัดรับฟัง ปัญหาและข้อเสนอทางออกทางเวปบอร์ด เพจ และสายด่วน ๐๙๒ ๔๖๒๙๖๑๖,๐๘๓ ๔๒๑๗๑๕๔`}</li>
      <li parentName="ul">{`จัด onsite เสาร์ที่ ๑๒ ตุลาคม ๒๕๖๗ เวลา 12-๑๗ นาฬิกา ณ.หอประชุมใหญ่มหาวิทยาลัยเกษตรศาสตร์ กทม.`}
        <ul parentName="li">
          <li parentName="ul">{`แพทย์ลาออกภาครัฐ และแพทย์เสียชีวิตก่อนวัยอันควรที่เกี่ยวข้องกับการทำงาน `}</li>
          <li parentName="ul">{`ปัญหาระบบการแพทย์ของประเทศโดยรวม  ในวันประชุมใหญ่ของแพทย์ `}</li>
        </ul>
      </li>
      <li parentName="ul">{`สำรวจและจัดทำรายงานปัญหาแพทย์จบใหม่ ลาออกและสียชีวิตในเวรกับหลังลงเวร ช่วง ๘ ปี (พ.ศ.๒๕๖๐-๒๕๖๗) `}</li>
      <li parentName="ul">{`สำรวจและจัดทำรายงานปัญหาระบบบริการทางการแพทย์และสาธารณสุขของประเทศ พ.ศ.๒๕๖๗`}</li>
    </ul>
    <h3 {...{
      "id": "บทกลอนทีมเพื่อแพทย์-คือ-แพทย์ไทยสามัคคี"
    }}>{`บทกลอนทีมเพื่อแพทย์ คือ แพทย์ไทยสามัคคี`}</h3>
    <pre><code parentName="pre" {...{}}>{`รวมแพทย์ไทย นั้นมั่น ญาติมิตร   มวลรวมจิตร นั้นหมาย แม่นมั่น
รวมแพทย์ไทย นั้นมั่น อุดมการณ์   มวลผูกพัน นั้นอยู่คู่ มวลหมู่เรา
`}</code></pre>
    <p><em parentName="p">{`ประพันธ์ โดย นพ.ฐาปนวงศ์ ตั้งอุไรวรรณ พ.ศ.๒๕๔๖`}</em></p>
    <h3 {...{
      "id": "ทีมเพื่อแพทย์-๓๔-คน"
    }}>{`ทีมเพื่อแพทย์ ๓๔ คน`}</h3>
    <ul>
      <li parentName="ul">{`๓๔  นพ.ฐาปนวงศ์ ตั้งอุไรวรรณ	จักษุแพทย์    โรงพยาบาลพระนั่งเกล้า นนทบุรี (พ.ศ. … ถึง …)	`}</li>
      <li parentName="ul">{`๓๕  ศ.นพ.สมชาย อมรโยธิน	วิสัญญีแพทย์  คณะแพทยศาสตร์ศิริราชพยาบาล (ปัจจุบัน)	`}</li>
      <li parentName="ul">{`๓๖  นพ.ไอศวรรย์ รักชาติ รองนายแพทย์สาธารณสุขจังหวัดนครพนม (ปัจจุบัน)	`}</li>
      <li parentName="ul">{`๓๗  นพ.รังสรรค์ บุตรชา ศัลยแพทย์  ผู้อำนวยการโรงพยาบาลธัญบุรี`}</li>
      <li parentName="ul">{`๓๘ นพ.ไพวัณ วัฒนราษฎร์ ศัลยแพทย์  หัวหน้ากลุ่มงานศัลยกรรม โรงพยาบาลสรรพสิทธิประสงค์ - อุบลราชธานี`}</li>
      <li parentName="ul">{`๓๙ นพ.ทนง คำศรี รองนายแพทย์สาธารณสุขจังหวัดอุบลราชธานี (ปัจจุบัน)`}</li>
      <li parentName="ul">{`๔๐ นพ.คำนึง สีแก่น คลินิกหมอคำนึง นครสววรค์ `}</li>
      <li parentName="ul">{`๔๑ นพ.ศิริวัฒน์ จาระเวชสาร สูติแพทย์  โรงพยาบาลปัตตานี (ปัจจุบัน) `}</li>
      <li parentName="ul">{`๔๒ นพ.ยลชัย จงจิระศิริ ศัลยแพทย์ออร์โธพิดิกส์   ผู้อำนวยการโรงพยาบาลลาดกระบัง กทม.(พ.ศ…ถึง…)`}</li>
      <li parentName="ul">{`๔๓ อ.นพ.อรรถพล สุคนธาภิรมย์ ณ พัทลุง จิตแพทย์   อาจารย์วิทยาลัยแพทย์นานาชาติ จุฬาลงกรณมหาวิทยาลัย`}</li>
      <li parentName="ul">{`๔๔ อ.พญ.ชนิฎา ศิริประภารัตน์	จิตแพทย์ อาจารย์วิทยาลัยแพทย์นานาชาติ จุฬาลงกรณมหาวิทยาลัย`}</li>
      <li parentName="ul">{`๔๕ พญ.ซายน์ เมธาดิลกกุล ศัลยแพทย์ออร์โธพิดิกส์ โรงพยาบาลเลิดสิน กรมการแพทย์ (ปัจจุบัน)`}</li>
      <li parentName="ul">{`๔๖ นพ. สมนึก ศิริพานทอง จักษุแพทย์ โรงพยาลสรรพสิทธิประสงค์ (พ.ศ. … ถึง…)`}</li>
      <li parentName="ul">{`๔๗ นพ.อัศวเดช แสนบัว แพทย์มะเร็งวิทยา โรงพยาบาลพานาซี`}</li>
      <li parentName="ul">{`๔๘นพ. มนตรี  คำมูลตา มนตรีคลินิกเวชกรรม นครปฐม`}</li>
      <li parentName="ul">{`๔๙ นพ.มนตรี จีรทวีสุข คลินิกหมอมนตรี นครปฐม`}</li>
      <li parentName="ul">{`๕๐ พญ.ณภัทร รัตนประภานันท์ วิสัญญีแพทย์ รพ.เชียงรายประชานุเคราะห์ (พ.ศ. … ถึง..)`}</li>
      <li parentName="ul">{`๕๑ พญ.เบญญาภา พรหมทะสาร แพทย์นักกฎหมาย  อุดรธานี`}</li>
      <li parentName="ul">{`๕๒ นพ.ธนกร ศรีบานชื่น แพทย์คลินิกชุมชนอบอุ่นมิตรไมตรี (พ.ศ… ถึง…)`}</li>
      <li parentName="ul">{`๕๓ รศ.นพ.ศุภชัย ถนอมทรัพย์ คณะแพทยศาสตร์โรงพยาบาลรามธิบดี (พ.ศ. … ถึง …)`}</li>
      <li parentName="ul">{`๑๗๕ นพ.มัซลัน ตะเระ ผู้อำนวยการโรงพยาบาลธารโต จ.ยะลา`}</li>
      <li parentName="ul">{`๑๗๖ นพ.สมหมาย บุญเกลี้ยง สูติแพทย์ โรงพยาบาลยะลา (พ.ศ. ..ถึง…) และรองผอ.ศอบต.(ปัจจุบัน)`}</li>
      <li parentName="ul">{`๑๗๗ นพ.ราชัย ราชมุกดา ศัลยแพทย์ออร์โธพิดิกส์   โรงพยาบาลราษฎร์นรา นราธิวาส`}</li>
      <li parentName="ul">{`๑๗๘ พลอากาศตรี นพ.เฉลิมพร บุญสิริ กุมารแพทย์ โรงพยาบาล… (พ.ศ… ถึง…)`}</li>
      <li parentName="ul">{`๑๗๙ นพ.อุกฤษฏ์ ศรีบรรเทา รังสีแพทย์ โรงพยาบาเจ้าพระยายมราช สุพรรณบุรี (ปัจจุบัน)`}</li>
      <li parentName="ul">{`๑๘๐ นพ.พงษ์ศักดิ์ ศรีมุษิกโพธิ์ คลินิกนายแพทย์พงษ์ศักดิ์  กรุงเทพมหานคร`}</li>
      <li parentName="ul">{`๑๘๑ พญ.นลินรัตน์  อมรพิมลธรรม คลินิก พญ.นลินรัตน์  กรุงเทพมหานคร `}</li>
      <li parentName="ul">{`๑๘๒ พญ. ณัฐณิชา ลอยชื่น นิชาคลินิกเวชกรรม พัทยา ชลบุรี`}</li>
      <li parentName="ul">{`๑๘๓ นพ.วัชระ พุ่มประดิษฐ์ อายุรแพทย์ โรงพยาบาลบำรุงราษฎร์ `}</li>
      <li parentName="ul">{`๑๘๔ นพ.โลเกศ ยอดล้ำ คลินิกหมอโลเกศ กรุงเทพมหานคร`}</li>
      <li parentName="ul">{`๑๘๕ นพ.สมยศ กิตติมั่นคง คลินิก นพ.สมยศ กรุงเทพมหานคร`}</li>
      <li parentName="ul">{`๑๘๖ นพ.โอม สุดชุมแพ โรงพยาบาลพานาซี `}</li>
      <li parentName="ul">{`๑๘๗ นพ.ภัทรพันธ์ ศักดา แข็งแรงคลินิกเวชกรรม  กรุงเทพมหานคร`}</li>
      <li parentName="ul">{`๑๘๘ พญ.อรพรรณ์ เมธาดิลกกุล แพทย์ระบาดวิทยา ผู้อำนวยการสำนักกฎหมายการแพทย์ `}</li>
    </ul>
    <p>{`กรมการแพทย์ (พ.ศ.๒๕๕๗-๒๕๕๙)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      